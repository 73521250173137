<!-- 申报申请  用于渲染政策表单 -->
<template>
  <div>
    <div>
      <v-btn class="ml-2 fl" @click="back()">
        返回上一级
      </v-btn>

      <v-btn class="ml-2 fl" @click="onPreviewDeclarePolicy">
        预览
      </v-btn>
      <!-- <v-btn class="ml-2 fl" @click="onPreviewDeclar">
                <v-icon small class="mr-1" color="#1090e7">remove_red_eye</v-icon>
                预览
            </v-btn> -->

      <el-breadcrumb separator-class="el-icon-arrow-right" class="fr nav">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>申报申请</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="clear: both;height: 16px"></div>

    <h2 class="policy-title">{{ policyDeclare.title }}</h2>

    <div class="policy-time">
      开始时间:
      <span class="mr-3">{{ policyDeclare.createTime | formatDate }}</span>
      结束时间: <span>{{ policyDeclare.expireTime | formatDate }}</span>
    </div>

    <v-card flat>
      <v-card-text class="text-content">
        <el-form label-width="auto" style="width: 100%;">
          <v-row style="width: 100%">
            <span v-for="(item, key) in fromData" :key="key" :class="item.name">
              <v-col cols="6" v-if="item.label === '户籍所在地'">
                <span>{{ item.label }}:</span>
                <!-- <input class="input-style-disabled"
                                       v-model="item.value"
                                       v-if="item.type==='text'"
                                       :type="item.type"
                                       :class="item.type"
                                       :disabled="true"/> -->
                <div :title="item.value" style="width: 200px;overflow: hidden;">
                  {{ item.value }}
                </div>
              </v-col>
              <span v-else-if="item.type === 'file'"></span>
              <span v-else-if="item.label === '个人简历'"></span>
              <v-col v-else>
                <span>{{ item.label }}:</span>
                <div
                  v-if="
                    item.label === '姓名' ||
                      item.label === '民族' ||
                      item.label === '人才类型' ||
                      item.label === '毕业学校' ||
                      item.label === '居民身份证' ||
                      item.label === '联系方式' ||
                      item.label === '户籍所在地' ||
                      item.label === '现工作单位及职务' ||
                      item.label === '毕业学校及专业' ||
                      item.label === '家庭地址'
                  "
                >
                  <input
                    class="input-style-disabled"
                    v-model="item.value"
                    :title="item.value"
                    v-if="item.type === 'text'"
                    :type="item.type"
                    :class="item.type"
                    :disabled="true"
                  />
                </div>
                <div v-else>
                  <input
                    class="input-style"
                    v-model="item.value"
                    v-if="item.type === 'text'"
                    :type="item.type"
                    :class="item.type"
                  />
                </div>
                <div v-if="item.label === '性别'">
                  <el-radio-group
                    v-model="item.value"
                    v-if="item.type === 'radio'"
                    class="ml-2"
                    :disabled="true"
                  >
                    <el-radio
                      v-for="(list, i) in item.options"
                      :key="i"
                      :label="list.name"
                    >
                      {{ list.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div v-else>
                  <el-radio-group
                    v-model="item.value"
                    v-if="item.type === 'radio'"
                    class="ml-2"
                  >
                    <el-radio
                      v-for="(list, i) in item.options"
                      :key="i"
                      :label="list.name"
                    >
                      {{ list.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <span v-if="item.type === 'select'" class="select">
                  <select
                    class="input-style-disabled"
                    v-model="item.value"
                    v-if="item.label === '学历学位'"
                    :disabled="true"
                  >
                    <option
                      v-for="list in item.options"
                      :key="list.value"
                      :label="list.label"
                      :value="list.value"
                    >
                      {{ list.name }}
                    </option>
                  </select>
                  <select class="input-style" v-model="item.value" v-else>
                    <option
                      v-for="list in item.options"
                      :key="list.value"
                      :label="list.label"
                      :value="list.value"
                    >
                      {{ list.name }}
                    </option>
                  </select>
                </span>

                <span v-if="item.label === '出生日期'" class="sh8jian">{{
                  item.value
                }}</span>
                <el-date-picker
                  size="small"
                  class="ml-2"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  v-if="item.type === 'date'"
                  v-model="item.value"
                  placeholder="选择日期"
                  style="width: 155px"
                  :disabled="item.label === '出生日期'"
                  :type="item.type"
                >
                </el-date-picker>
              </v-col>
            </span>
          </v-row>
          <table
            width="800"
            height="700"
            border="0"
            cellspacing="1"
            bgcolor="#ddd"
          >
            <tr>
              <td width="90" height="52" align="center" bgcolor="#FFFFFF">
                姓名
              </td>
              <td width="99" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td width="96" align="center" bgcolor="#FFFFFF">性别</td>
              <td width="95" align="center" bgcolor="#FFFFFF">{{ sex }}</td>
              <td width="48" align="center" bgcolor="#FFFFFF">民族</td>
              <td width="47" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td width="44" align="center" bgcolor="#FFFFFF">健康 状况</td>
              <td width="102" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td width="101" rowspan="3" align="center" bgcolor="#FFFFFF">
                <img :src="filesAvatar.path" alt="" width="100%" />
              </td>
            </tr>
            <tr>
              <td height="53" align="center" bgcolor="#FFFFFF">出生年月</td>
              <td align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td align="center" bgcolor="#FFFFFF">政治 面貌</td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td align="center" bgcolor="#FFFFFF">人才 类别</td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
            </tr>
            <tr>
              <td height="54" align="center" bgcolor="#FFFFFF">
                身份证号码(护照号码)
              </td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">户籍所在地</td>
              <td colspan="3" align="center" bgcolor="#FFFFFF"></td>
            </tr>
            <tr>
              <td align="center" bgcolor="#FFFFFF">引进前单位 及职务</td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td colspan="2" align="center" bgcolor="#FFFFFF">引进时间</td>
              <td colspan="3" align="center" bgcolor="#FFFFFF">&nbsp;</td>
              <td align="center" bgcolor="#FFFFFF">&nbsp;</td>
            </tr>
            <tr>
              <td align="center" valign="middle" bgcolor="#FFFFFF">
                现工作单位 及职务
              </td>
              <td colspan="4" align="center" valign="middle" bgcolor="#FFFFFF">
                &nbsp;
              </td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">
                联系 方式
              </td>
              <td colspan="3" align="center" valign="middle" bgcolor="#FFFFFF">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle" bgcolor="#FFFFFF">
                毕业学校 及专业
              </td>
              <td colspan="4" align="center" valign="middle" bgcolor="#FFFFFF">
                &nbsp;
              </td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">
                学历 学位
              </td>
              <td colspan="3" align="center" valign="middle" bgcolor="#FFFFFF">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle" bgcolor="#FFFFFF">家庭住址</td>
              <td colspan="8" align="center" valign="middle" bgcolor="#FFFFFF">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td rowspan="2" align="center" valign="middle" bgcolor="#FFFFFF">
                家庭 主要 社会 关系
              </td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">称谓</td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">姓名</td>
              <td colspan="2" align="center" valign="middle" bgcolor="#FFFFFF">
                出生年月
              </td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">
                政治 面貌
              </td>
              <td colspan="3" align="center" valign="middle" bgcolor="#FFFFFF">
                现工作单位及职务
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
              <td colspan="2" align="center" valign="middle" bgcolor="#FFFFFF">
                .
              </td>
              <td align="center" valign="middle" bgcolor="#FFFFFF">.</td>
              <td colspan="3" align="center" valign="middle" bgcolor="#FFFFFF">
                {{ parentJobtitle }}
              </td>
            </tr>
            <tr>
              <td height="251" align="center" valign="middle" bgcolor="#FFFFFF">
                个 人 简 历 及 所 获 荣 誉
              </td>
              <td colspan="8" align="center" valign="middle" bgcolor="#FFFFFF">
                <textarea
                  name=""
                  id=""
                  cols="90"
                  rows="10"
                  v-if="isResume"
                  v-model="resume"
                  placeholder="输入个人简历..."
                ></textarea>
                <p v-else>{{ resume }}</p>
              </td>
            </tr>
          </table>

          <!-- <v-row style="width: 100%" v-if="isResume">
                        <v-col cols="12">
                            个人简历:
                            <div class="policy-resume">
                                {{ resume }}
                            </div>
                        </v-col>
                    </v-row> -->
          <!-- 附件预览 -->
          <!-- <el-card shadow="never" style="width: 100%" class="file-card-height">
            <el-col :span="6" v-for="(item, ke) in previewFiles" :key="ke">
              <el-form-item class="file-card">
                <el-button
                  plain
                  class="yiyoufuj"
                  icon="el-icon-connection"
                  @click="haPreview(item)"
                  >{{ item.memo }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-card> -->

          <v-row style="width: 100%" class="file-card-height">
            <div v-for="(item, key) in bviewFiles" :key="key">
              <div
                v-if="
                  item.label !== '身份证_正' &&
                    item.label !== '身份证_反' &&
                    item.label !== '工商营业执照'
                "
              >
                <span v-if="item.type === 'file'">{{ item.label }}</span>
                <el-form-item class="file-card" v-if="item.type === 'file'">
                  <el-upload
                    class="upload-demo"
                    :accept="isUploadType(item, item.name)"
                    :class="
                      'upload-demo' +
                        parseInt(item.name.split('talentUpload')[1])
                    "
                    :action="host + '/file'"
                    :on-success="handleSuccess"
                    :before-upload="
                      isUploadType(item, item.name) == '.png'
                        ? beforeAvatarUpload
                        : beforePdfUpload
                    "
                    :limit="1"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :on-preview="handlePreview"
                    :headers="importHeaders"
                    :file-list="
                      fileList[parseInt(item.name.split('talentUpload')[1]) - 1]
                    "
                    show-file-list
                  >
                    <el-button
                      size="small"
                      type="text"
                      class="file-button"
                      @click="setlabel(item.label, item.name)"
                    >
                      <v-icon class="mr-1" color="#1090e7">cloud_upload</v-icon>
                      点击上传
                    </el-button>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </v-row>
        </el-form>
      </v-card-text>
      <v-card-text>
        <!-- <el-tooltip class="item" effect="dark" content="暂存功能不会存储上传的附件" placement="bottom">
                    <el-button class="submit" @click="requestDraft">暂存到草稿箱</el-button>
                </el-tooltip> -->
        <el-button type="primary" class="submit text-color" @click="Submit"
          >确认提交</el-button
        >
        <!-- <div style="text-align:center">注：暂存不会保存附件！</div> -->
      </v-card-text>
    </v-card>
    <el-dialog title="查看" :visible.sync="pisDialog" width="30%" center>
      <div class="pisDialog">
        <img alt="" :src="pispath" v-if="pisDialog" style="width: 100%" />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pisDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <v-dialog
      v-model="dialogDeclarePolicy"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#f7f7f7">
        <v-card-title class="headline grey lighten-2">
          预览申报政策信息
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeclarePolicy = false">
            关闭
          </v-btn>
        </v-card-title>

        <v-card-text>
          <declare
            :item="itemDeclarePolicyInformation"
            :resume="itemResume"
            :file="itemFiles"
            v-if="dialogDeclarePolicy"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <el-dialog title="PDF预览" :visible.sync="dialogDeclare" width="80%" center>
      <div class="dialogDeclare">
        <iframe :src="Pdf" frameborder="0" v-if="Pdf"></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDeclare = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
import Declare from "@/components/personal/declare";

export default {
  data() {
    return {
      dialogDeclarePolicy: false, // 政策申报
      dialogDeclare: false,
      pdfFileobj: {},
      Pdf: "",
      sex: "男",
      newfoms: {},
      itemDeclarePolicyInformation: [], // 打开预览时传给子组件的值
      itemResume: "", // 打开预览时传给子组件的个人简历
      itemFiles: "", // 打开预览时传给子组件的文件
      previewFiles: [], //上传的所有文件 预览
      sviewFiles: [], //已经有的文件
      EwFiles: [], //后面上传
      filesAvatar: {},
      suoviewFiles: [], //全部需要填的控件
      bviewFiles: [], //有的但是需要传的文件
      policyDeclare: {},
      birthplace: "", //birthplace
      oneEdu: {}, //第一学历
      resume: "", //个人简历
      parentJobtitle: "现职务", //现职务
      importHeaders: { token: sessionStorage.getItem("Token") },
      loading: false,
      isResume: false,
      filelabel: "",
      pispath: "",
      pisDialog: false,
      flag: false,
      nolabel: [],
      filename: "",
      host: configDate.url,
      id: this.$route.params.id,
      //TalentStatus:0,
      policycon: {},
      fromData: [],
      tableData: {},
      finalData: {
        formId: "",
        policyId: this.$route.params.id,
        userId: sessionStorage.getItem("ID"),
        values: [],
      },
      newForm: {},
      newValue: "",
      fileList: [],
      draftId: false,
      updateDraft: {
        id: "",
        values: [],
      },
      policyId: "",
      enterpriseId: "",
      lintsID: "",
      pickerOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
    };
  },
  created() {
    if (this.$route.params.id.split("&")[0]) {
      this.id = this.$route.params.id.split("&")[0];
    }
    this.updateDraft.id = this.$route.params.id.split("&")[1];
    this.finalData.policyId = this.$route.params.id.split("&")[0];
    this.getDate(); //初始化数据
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    //判断上传控件类型
    isUploadType(data, name) {
      let setType = "";
      if (data.label === "个人所得税") {
        console.log(data.label);
        setType = ".pdf";
        return setType;
      } else {
        setType = name.indexOf("association") != -1 ? ".pdf" : ".png";
        return setType;
      }
    },

    //返回上一级
    back() {
      this.$router.back();
    },
    // 控制时间不可以选择未来时间
    dealDisabledDate(time) {
      let times = Date.now();
      return time.getTime() > times;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isjpeg = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (isPNG) {
      } else {
        this.$message.error("图片只能是 png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },

    beforePdfUpload(file) {
      console.log(file);
      const ispdf = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (ispdf) {
      } else {
        this.$message.error("图片只能是 pdf 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },

    //获取企业信息( 获取工商执照)
    geTenterprise(Id) {
      console.log(Id);
      const self = this;
      this.$axios
        .get("/enterprise", {
          params: {
            id: Id,
          },
        })
        .then(function(res) {
          let files = res.data.data.files;
          files.forEach((item) => {
            if (item.memo == "工商营业执照") {
              self.EwFiles.push({
                id: item.id,
                name: "businesslicense",
                path: item.path,
              });
            }
          });
        });
    },
    ff() {
      const self = this;
      this.$axios
        .get("/talent", {
          params: {
            id: self.finalData.userId,
          },
        })
        .then(function(res) {
          self.pdfFileobj.enterpriseId = res.data.data.enterpriseId + "";
          self.geTenterprise(res.data.data.enterpriseId);
        });
    },

    getTalent() {
      const self = this;
      let newData = self.fromData;

      this.$axios
        .get("/talent/detail", {
          params: {
            id: self.finalData.userId,
          },
        })
        .then(function(res) {
          self.birthplace = res.data.data.birthplace;
          self.parentJobtitle = res.data.data.talentOccupation.position;
          self.pdfFileobj = {
            talentId: res.data.data.id,
            enterpriseId: "",
            policyId: self.policyId,
          };
          self.ff();

          self.resume = res.data.data.resume;
          if (res.data.data.status !== 21) {
            self.$message({
              showClose: true,
              message: "您的账号还没有认证通过！",
              type: "warning",
            });
            self.$router.push("/probate");
          } else {
            res.data.data.files.forEach(function(item) {
              if (item.memo == "免冠工作照") {
                self.filesAvatar = {
                  id: item.id,
                  name: item.memo,
                  memo: item.name,
                  path: configDate.fileurl + item.path,
                };
              }
              //console.log(self.filesAvatar)
              //filesAvatar
              self.sviewFiles.push({
                id: item.id,
                name: item.memo,
                memo: item.name,
                path: item.path,
              });
            });
            //console.log(suoviewFiles)//所以附件
            // self.sviewFiles.forEach((im,index)=>{
            //     console.log(self.suoviewFiles[index].label)
            //     for (let key in self.suoviewFiles) {
            //         if (self.suoviewFiles[key].label === im.name){
            //             console.log(im.name)
            //         }
            //     }
            // })

            // self.suoviewFiles.forEach((im, index) => {
            //   for (let key in self.sviewFiles) {
            //     if (im.label) {
            //       if (self.sviewFiles[key].name === im.label) {
            //         self.previewFiles.push(self.sviewFiles[key]);
            //       }
            //     }
            //   }
            // });
            // console.log(self.previewFiles);

            const arr2 = self.suoviewFiles;
            const arr1 = self.sviewFiles; //两个身份证

            var resultArr = [];
            arr1.forEach((item) => {
              if (item) {
                resultArr.push(item);
              }
              // resultArr.push(
              //   arr1.filter((_item) => _item.name === item.label)[0]
              // );
            });

            resultArr.forEach((item) => {
              if (item) {
                self.previewFiles.push(item);
              }
            });

            //let Sdate = self.previewFiles.filter(item => self.suoviewFiles.label.indexOf(item.name) > -1)
            //console.log(Sdate)
            self.bviewFiles = self.suoviewFiles.filter((item) => {
              let idList = self.previewFiles.map((v) => v.name);
              return !idList.includes(item.label);
            });

            // self.previewFiles.forEach((im,index)=>{
            //     console.log(im.name)
            //     for (let key in self.suoviewFiles) {
            //             //if(self.suoviewFiles[key].label==im.name){console.log(self.suoviewFiles[key].label,im.name)}
            //
            //     }
            //
            // })
            console.log(self.previewFiles);
            newData.forEach(function(items) {
              for (let key in res.data.data) {
                if (items.name === key) {
                  if (items.name === "sex") {
                    items.value = res.data.data[key] === 0 ? "男" : "女";
                    self.sex = items.value;
                  } else {
                    items.value = res.data.data[key];
                  }
                }
              }
            });
            // 提取第一学历

            res.data.data.educations.forEach((eduitem) => {
              if (eduitem.sort == 0) {
                self.oneEdu = eduitem;
              }
            });

            newData.forEach(function(items) {
              if (items.label === "学历学位") {
                items.value = self.oneEdu.academic;
              }
              if (items.label === "毕业学校及专业") {
                items.value = self.oneEdu.school + " " + self.oneEdu.major;
              }
              if (items.label === "联系方式") {
                items.value = res.data.data.account.contact;
              }
              // if (items.label === '专业') {
              //     items.value = self.oneEdu.major
              // }
              if (items.label === "户籍所在地") {
                items.value =
                  res.data.data.addresses[0].province +
                  res.data.data.addresses[0].city +
                  res.data.data.addresses[0].county +
                  res.data.data.addresses[0].details;
              }
              if (items.label === "家庭地址") {
                items.value = res.data.data.addresses[0].details;
              }
              if (items.label === "个人简历") {
                items.value = res.data.data.resume;
              }
              if (items.label === "现工作单位及职务") {
                items.value =
                  res.data.data.enterpriseName +
                  " " +
                  res.data.data.talentOccupation.position;
              }
              if (items.label === "个人简历") {
                if (items.value == "") {
                  self.isResume = true;
                }
              }
            });
            self.tableData = res.data.data;
            self.newForm = res.data.data;
          }
        });
    },

    getDate() {
      var self = this;
      //查询政策及动态表单
      this.$axios
        .get("/policy", {
          params: {
            id: self.id,
          },
        })
        .then(function(res) {
          if (res.data.status === 200) {
            self.policyId = res.data.data.id;
            let DateOld = res.data.data.form.inputs;

            for (const key in DateOld) {
              if (DateOld[key].type === "checkbox") {
                DateOld[key].value = [];
              } else if (DateOld[key].type === "file") {
                self.suoviewFiles.push(DateOld[key]);
              } else {
                DateOld[key].value = "";
              }
              self.fromData.push(DateOld[key]);
            }

            self.policyDeclare = res.data.data;
            self.policycon = res.data.data;
            self.finalData.formId = res.data.data.form.id;
            //console.log(res.data.data.form.inputs);

            let obj = {};
            // res.data.data.form.inputs.forEach((item) =>{

            //     obj[item.name]=item.value
            //     console.log(item.value);
            // });
            self.$axios
              .get("/affair/drafts?userId=" + self.finalData.userId)
              .then((response) => {
                if (response.data.status === 200) {
                  for (let i = 0; i < response.data.data.records.length; i++) {
                    if (
                      self.policyId === response.data.data.records[i].policyId
                    ) {
                      self.updateDraft.id = response.data.data.records[i].id;
                      self.draftId = true;
                      self.lintsID = response.data.data.records[i].id;
                      console.log(self.lintsID);
                      self.getDraft(self.lintsID);
                      return false;
                    } else {
                      self.getTalent();
                    }
                  }
                } else if (
                  response.data.data === "draft [1] by [page] not exists."
                ) {
                  self.getTalent();
                } else {
                  self.$message({
                    showClose: true,
                    message: "请刷新！",
                    type: "warning",
                  });
                }
              });
          } else {
            self.$message({
              showClose: true,
              message: "该政策已被删除",
              type: "error",
            });
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    requestDraft() {
      var map = new Map();
      let objData = [];
      for (const key in this.fromData) {
        if (this.fromData[key].type !== "file") {
          map.set(this.fromData[key].name, this.fromData[key].value);
        }
      }
      for (let [k, v] of map) {
        objData.push({
          name: k,
          value: v,
        });
      }
      this.finalData.values = this.finalData.values.concat(objData);
      this.updateDraft.values = this.finalData.values.concat(objData);

      if (this.draftId === true) {
        this.$axios.put("/affair/draft", this.updateDraft).then((response) => {
          if (response.data.status === 200) {
            this.$root.success("更新成功!");
            this.$router.replace({
              path: "/declarelist",
              query: { open: 1 },
            });
            this.getDraft(this.lintsID);
          } else {
            this.$root.warn("更新失败！");
          }
        });
      } else {
        this.$axios.post("/affair/draft", this.finalData).then((response) => {
          if (response.data.status === 200) {
            this.$root.success("存储成功!");
            this.$router.replace({
              path: "/declarelist",
              query: { open: 1, draft: true },
            });
            this.updateDraft.id = response.data.data;
            this.draftId = true;
            sessionStorage.setItem("draftId", response.data.data);
            this.getDraft(this.lintsID);
          } else {
            this.$root.warn("存储失败！");
          }
        });
      }
    },
    getDraft(id) {
      const self = this;
      let newData = self.fromData;
      this.$axios
        .get("/talent", {
          params: {
            id: self.finalData.userId,
          },
        })
        .then(function(res) {
          if (res.data.data.status !== 21) {
            self.$message({
              showClose: true,
              message: "您的账号还没有认证通过！",
              type: "warning",
            });
            self.$router.push("/probate");
          } else {
            self.$axios
              .get("/affair/draft?affairDraftId=" + id)
              .then(function(response) {
                if (res.data.status === 200) {
                  self.draftId = true;
                  for (let i = 0; i < response.data.data.values.length; i++) {
                    newData.forEach((items) => {
                      if (items.name === response.data.data.values[i].name) {
                        if (items.name === "sex") {
                          items.value =
                            response.data.data.values[i].value === 0
                              ? "男"
                              : "女";
                        } else {
                          items.value = response.data.data.values[i].value;
                        }
                      }
                    });
                  }
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    },

    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    Progress() {
      this.loading = true;
    },
    Isnolabel(label) {
      for (const labelKey in this.nolabel) {
        if (this.nolabel[labelKey] === label) {
          return true;
        } else {
          return false;
        }
      }
    },

    setlabel(lebalname, filename) {
      this.filelabel = lebalname;
      this.filename = filename;
    },

    //上传附件
    handleSuccess(response, file) {
      let self = this;
      if (response.status === 200) {
        this.loading = false;
        let data = {
          id: response.data.id,
          memo: self.filelabel,
        };

        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            self.$message({
              showClose: true,
              message: "上传成功",
              type: "success",
            });
            // self.previewFiles.push({
            //   id: response.data.id,
            //   memo: self.filelabel,
            //   path: response.data.path,
            // });
            self.EwFiles.push({
              id: response.data.id,
              name: self.filename,
              path: response.data.path,
            });
            // self.finalData.values.push({
            //   name: self.filename,
            //   value: file.response.data.id + "",
            // });
            console.log(self.previewFiles);
          }
        });
      } else {
        this.loading = false;
        this.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    haPreview(file) {
      // console.log(file)

      let pdfFile = file.memo.split(".");

      if (pdfFile[1] === "pdf") {
        //下载链接
        let uesurl = configDate.href + "/file/object?path=" + file.path;
        console.log(uesurl);
        if (confirm("需要下载吗")) {
          this.getDownloadFile(uesurl);
          //window.open(file.url ||configDate.url + '/file/object/preview?path=' + file.path,"_blank");
        } else {
          window.open(
            file.url ||
              configDate.url + "/file/object/preview?path=" + file.path,
            "_blank"
          );
        }
      } else {
        this.pisDialog = true;
        this.pispath =
          file.url || configDate.url + "/file/object?path=" + file.path;
      }
    },

    // 下载pdf文件
    getDownloadFile(item) {
      const link = document.createElement("a");
      link.style.display = "none";

      link.href = item;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.pisDialog = false;
    },

    // 打开预览
    onPreviewDeclarePolicy() {
      this.itemResume = this.resume;
      this.itemDeclarePolicyInformation = this.fromData;
      this.itemFiles = this.previewFiles;
      this.dialogDeclarePolicy = true;
    },
    // 打开PDF预览
    onPreviewDeclar() {
      this.dialogDeclare = true;

      this.$axios
        .get("/export", {
          params: {
            affairId: this.pdfFileobj.policyId,
            talentId: this.pdfFileobj.talentId,
            enterpriseId: this.pdfFileobj.enterpriseId,
          },
        })
        .then(function(res) {});
      console.log(this.pdfFileobj);
    },

    Submit() {
      this.$confirm("提交后不可再修改，确定要提交吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var self = this;
          if (!this.flag) {
            this.flag = true;
            var map = new Map();
            let objData = []; //

            for (const key in this.fromData) {
              if (this.fromData[key].type !== "file") {
                map.set(this.fromData[key].name, this.fromData[key].value);
              }
            }
            for (let [k, v] of map) {
              objData.push({
                name: k,
                value: v,
              });
            }

            //previewFiles
            let FormValue = self.policyDeclare.form.inputs;
            //console.log(FormValue);
            let IDcars = [];
            for (let index = 0; index < FormValue.length; index++) {
              const el = FormValue[index];

              self.previewFiles.forEach((item) => {
                let labels = el.label.split("_");
                if (labels[1]) {
                  if (item.name === "身份证") {
                    IDcars.push(item);
                  }
                } else if (item.name === el.label) {
                  objData.push({
                    name: el.name,
                    value: item.id,
                  });
                }
              });
            }
            self.EwFiles.forEach((item) => {
              objData.push({
                name: item.name,
                value: item.id,
              });
            });
            let ceq = ["talentIDcardPositive", "talentIDcarDnegative"];
            let deWeightThree = () => {
              let map = new Map();
              for (let item of IDcars) {
                if (!map.has(item.id)) {
                  map.set(item.id, item);
                }
              }
              return [...map.values()];
            };
            let IDcar = deWeightThree();

            IDcar.forEach((item, index) => {
              objData.push({
                name: ceq[index],
                value: item.id,
              });
            });

            this.finalData.values = this.finalData.values.concat(objData);
          }

          let id = sessionStorage.getItem("draftId");

          if (this.isResume) {
            this.finalData.values.forEach((item) => {
              if (item.name == "resume") {
                item.value = this.resume;
              }
            });
          }
          console.log(this.finalData);

          this.$axios
            .post("/affair", this.finalData)
            .then(function(res) {
              if (res.data.status === 200) {
                self.$message({
                  showClose: true,
                  message: "申报成功",
                  type: "success",
                });
                self.$router.push("/declarelist");
                self.$axios
                  .delete("/affair/draft/whole?affairDraftId=" + id)
                  .then((response) => {});
              } else {
                if (res.data.data === "create(expire) affairfailed") {
                  self.$message.error("已经过了政策申报的期限！");

                  self.$axios
                    .delete("/affair/draft/whole?affairDraftId=" + id)
                    .then((response) => {});
                } else if (res.data.data === "create(disable) affairfailed") {
                  self.$message.error("政策已关闭！");
                } else {
                  self.$message({
                    showClose: true,
                    message: res.data.data,
                    type: "warning",
                  });
                }
              }
              self.loading = false;
            })
            .catch(function(error) {
              console.error(error);
              self.loading = false;
            });
        })
        .catch(() => {});
    },
    handleRemove(file, fileList) {
      console.log(file.id);
    },
    beforeRemove(file, fileList) {
      if (file.response && file.response.data) {
        this.$axios
          .delete("/file/delete?id=" + file.response.data.id)
          .then((response) => {
            if (response.data.status === 200) {
              let newfileId = [];
              this.finalData.values.forEach(function(arr) {
                if (arr.value !== file.response.data.id) {
                  newfileId.push(arr);
                }
              });
              this.finalData.values = [...newfileId];
            }
          });
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
  components: {
    Declare,
  },
};
</script>

<style lang="less" scoped>
.policy-title {
  color: #128fec;
  text-align: left;
  margin: 0 0 12px 8px;
}

.policy-time {
  text-align: left;
  margin-left: 8px;
  margin-bottom: 16px;
}

.policy-resume {
  display: block;
  width: 100%;
  min-height: 80px;
  padding: 8px;
  border: 1px #8a8f97 dashed;
  background-color: #f5f7fa;
  border-radius: 8px;
}

.text-content {
  text-align: left;
  margin: 0 8px;
  color: #000000;
  font-size: 14px;

  span {
    color: #8a8f97;
  }
  .name,
  .sex,
  .nation,
  .healthy,
  .birthday,
  .politicalOutlook,
  .phone,
  .type,
  .identityCode,
  .lastEnterprise,
  .lastHireDate,
  .enterpriseName,
  .homeAddress,
  .academic,
  .school,
  .major,
  .registered,
  .resume,
  .parentTitle,
  .parentName,
  .parentBirth,
  .parentPolitical,
  .parentJobtitle {
    position: absolute;
    display: none;
  }
  .name {
    span {
      display: none;
    }
    display: block;
    top: 60px;
    width: 100px;
    left: 119px;
    .input-style-disabled {
      width: 90px;
      text-align: center;
    }
  }
  .birthday {
    span {
      display: none;
    }
    .sh8jian {
      display: block;
      height: 25px;
      overflow: hidden;
    }
    display: block;
    top: 90px;
    width: 100px;
    left: 140px;
    .el-date-editor {
      display: none;
    }
  }
  .identityCode {
    span {
      display: none;
    }
    display: block;
    top: 160px;
    width: 170px;
    left: 133px;
    .input-style-disabled {
      width: 150px;
      text-align: center;
    }
  }
  .lastEnterprise {
    span {
      display: none;
    }
    display: block;
    top: 213px;
    width: 200px;
    left: 130px;
    .input-style {
      width: 198px;
      text-align: center;
      margin: 0;
      border: 0;
      outline: none;
    }
  }
  .lastHireDate {
    span {
      display: none;
    }
    display: block;
    top: 210px;
    width: 200px;
    left: 500px;
    .input-style {
      width: 198px;
      text-align: center;
      margin: 0;
    }
  }
  .enterpriseName {
    span {
      display: none;
    }
    display: block;
    top: 270px;
    width: 330px;
    left: 130px;
    .input-style {
      width: 198px;
      text-align: center;
      margin: 0;
    }
  }
  .academic {
    span {
      display: none;
    }
    .select {
      display: block;
    }
    display: block;
    top: 310px;
    width: 330px;
    left: 560px;
  }

  .school {
    span {
      display: none;
    }
    display: block;
    top: 330px;
    width: 330px;
    left: 135px;
    .input-style-disabled {
      width: 333px;
      text-align: left;
      margin: 0;
    }
  }
  .major {
    span {
      display: none;
    }
    display: none;
    top: 338px;
    width: 330px;
    left: 135px;
    .input-style-disabled {
      width: 333px;
      text-align: left;
      margin: 0;
    }
  }
  .registered {
    span {
      display: none;
    }
    display: block;
    top: 155px;
    width: 200px;
    left: 505px;
    height: 40px;
    .input-style-disabled {
      width: 630px;
      text-align: left;
      margin: 0;
    }
  }
  .homeAddress {
    span {
      display: none;
    }
    display: block;
    top: 369px;
    width: 600px;
    left: 125px;
    height: 30px;
    .input-style {
      width: 630px;
      text-align: left;
      margin: 0;
      height: 27px;
      border: 0;
      outline: none;
    }
  }
  .parentTitle {
    span {
      display: none;
    }
    display: block;
    top: 457px;
    width: 110px;
    left: 128px;
    .input-style {
      width: 104px;
      text-align: center;
      margin: 0;
      padding: 2px 0;
      border: 0;
      outline: none;
    }
  }
  .parentName {
    span {
      display: none;
    }
    display: block;
    top: 454px;
    width: 100px;
    left: 235px;
    .input-style {
      width: 100px;
      text-align: center;
      margin: 0;
      padding: 2px 0;
      border: 0;
      outline: none;
    }
  }
  .parentBirth {
    span {
      display: none;
    }
    display: block;
    top: 453px;
    width: 168px;
    left: 330px;
    .input-style {
      width: 104px;
      text-align: center;
      margin: 0;
      padding: 2px 0;
    }
  }
  .parentPolitical {
    span {
      display: none;
    }
    display: block;
    top: 458px;
    width: 50px;
    left: 498px;
    background: #fff;
    .input-style {
      width: 50px;
      text-align: center;
      margin: 0;
      padding: 2px 0;
      border-radius: 0;
      border: 0;
      outline: none;
    }
  }
  .parentJobtitle {
    span {
      display: none;
    }
    display: block;
    top: 458px;
    width: 254px;
    left: 553px;
    height: 27px;

    background: #fff;
    .input-style {
      width: 255px;
      text-align: center;
      margin: 0;
      padding: 2px 0;
      border-radius: 0;
      border: 0;
      outline: none;
    }
  }
  .sex {
    display: none;
    span {
      display: none;
    }

    top: 51px;
    width: 100px;
    left: 344px;
  }
  .politicalOutlook {
    span {
      display: none;
    }
    display: block;
    top: 105px;
    width: 140px;
    left: 340px;
    .input-style {
      width: 100px;
      text-align: center;
      border: 0;
      outline: none;
    }
  }
  .nation {
    span {
      display: none;
    }
    display: block;
    top: 54px;
    width: 50px;
    left: 495px;
    .input-style-disabled {
      width: 48px;
      text-align: center;
    }
  }
  .type {
    span {
      display: none;
    }
    display: block;
    top: 110px;
    width: 140px;
    left: 555px;
    .input-style-disabled {
      width: 153px;
      text-align: center;
    }
  }
  .phone {
    span {
      display: none;
    }
    display: block;
    top: 275px;
    width: 200px;
    left: 550px;
    .input-style-disabled {
      width: 95px;
      text-align: center;
    }
  }
  .healthy {
    span {
      display: none;
    }
    display: block;
    top: 50px;
    width: 110px;
    left: 595px;
    .input-style {
      width: 100px;
      text-align: center;
      border: 0;
      outline: none;
    }
  }

  .el-input__inner {
    padding: 0;
  }
  .yiyoufuj {
    display: block;
    width: 200px;
    height: 60px;
    color: #1090e7;
    border: 1px #8a8f97 dashed;
    padding: 0 10px;
    border-radius: 10px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
  }
  div {
    display: inline-block;
    padding: 0;
  }

  .content {
    color: #2a3746;
  }
}

.file-card-height {
  margin: 0px 8px 0 8px;
  min-width: 200px;
  width: 100%;
  border: 0;
  .file-card {
    width: 100%;
    vertical-align: top;
  }

  .upload-demo {
    min-width: 200px;
    min-height: 56px;
    line-height: 56px;
    margin-top: 16px;
    padding-bottom: 8px;
    border: 1px #8a8f97 dashed;
    border-radius: 8px;

    .file-button {
      color: #0c94ea;
      display: inline-block;
      margin-left: 45%;
    }

    .file-name {
      display: inline-block;
      margin-left: 24px;
    }
  }
}

.input-style {
  color: #000000;
  min-width: 60%;
  margin: 0 8px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #8a8f97;
}

.input-style-disabled {
  color: #8a8f97;
  min-width: 70%;
  margin: 0 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f5f7fa;
  border: 1px solid #e4e7ed;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  color: #676767;
}

.text-color {
  color: #ffffff;
}

.ruleForm {
  width: 100%;
  text-align: left;
  min-height: 55vh;

  .el-form-item {
    margin: 0 8px;
    width: 400px;

    .el-form-item__content {
      padding: 0;
    }
  }

  .text {
    width: 375px;
    margin: 0 15px 0 0;
  }

  .textarea {
    width: 375px;
  }

  .upload {
    display: block;
  }
}

.submit {
  width: 150px;
}

.heder {
  border: 1px #e4f0fb solid;
  padding: 20px;
  text-align: left;

  h3 {
    margin: 0;
    line-height: 35px;
    color: #0f91e9;
  }

  .time {
    padding: 15px 0 0 0;

    span {
      padding: 0 10px 0 0;
      color: #909399;
    }
  }
}
textarea {
  resize: none;
}
.file-style {
  margin-top: -80px;
}

::v-deep .textl .el-upload {
  text-align: left;
}
</style>
