<template>
    <div class="mt-4">
<!--        <v-row no-gutters>-->
<!--            <v-col cols="3" v-for="(item,key) in tableDate" :key="key">-->
<!--                <v-card class="pa-2" outlined tile>-->
<!--                    <span>{{ item.label }} : </span>-->
<!--                    <span>{{ item.value }}</span>-->
<!--                </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="6" v-for="item in address" :key="item.id">-->
<!--                <v-card class="pa-2" outlined tile>-->
<!--                    <span>{{ item.label }} : </span>-->
<!--                    <span>{{ item.value }}</span>-->
<!--                </v-card>-->
<!--            </v-col>-->

<!--            <v-col cols="12">-->
<!--                <v-card class="pa-2" outlined tile>-->
<!--                    个人简历说明:-->
<!--                    <span>{{ resumeInformation }}</span>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->

<!--        <v-row class="file">-->
<!--            <v-col cols="3" class="left" v-for="item in files" :key="item.id">-->
<!--                <span @click="getSeePhoto(item)">{{ item.memo }}</span>-->
<!--            </v-col>-->
<!--        </v-row>-->


        <v-row>
            <v-col cols="9">
                <v-card flat>
                    <v-card-title class="authentication-information"><span></span>基本信息</v-card-title>
                    <v-card-text class="text-content">
                        <v-row>
                            <v-col cols="4" v-for="item in tableDate" :key="item.id">
                                <span>{{ item.label }}:</span>
                                <span class="content">{{ item.value }}</span>
                            </v-col>
                            <v-col cols="8" v-for="item in address" :key="item.id">
                                <span>{{ item.label }}:</span>
                                <span class="content">{{ item.value }}</span>
                            </v-col>
                        </v-row>

                        <v-col cols="12">
                            个人简历说明:
                            <div class="policy-resume">
                                {{ resumeInformation }}
                            </div>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="authentication-information"><span></span>附件材料查看下载</v-card-title>

                    <v-card-text>
                        <v-list dense>
                            <v-list-item-group v-model="itemFiles" color="primary">
                                <v-list-item
                                    v-for="item in files"
                                    :key="item.id">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.memo"
                                                           class="file-text"
                                                           @click="getSeePhoto(item)">{{item.memo}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog v-model="seePhotoDialog" scrollable width="60%">
            <v-card>
                <v-card-title>{{ photoTile }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div id="demo"></div>
                    <v-img :src="photoPath" contain aspect-ratio="2" alt=""/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="seePhotoDialog = false">关闭</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import url from "@/utils/config";

export default {
    name: "declare",
    props: {item: Array, resume: String,file: Array},
    data() {
        return {
            itemFiles: 0,
            seePhotoDialog: false,
            photoTile: "预览图片",
            photoPath: "",
            resumeInformation: "",
            tableDate: [],
            files: [],
            address: []
        }
    },
    methods: {
        getDate() {
            this.resumeInformation = this.resume;
            this.files = this.file
            this.item.forEach(item => {
                if (item.type !== "file") {
                    let tableDate = [];
                    tableDate.push(item)
                    tableDate.forEach(addressItem => {
                        if (addressItem.label === "户籍所在地") {
                            this.address.push(addressItem)
                        } else {
                            this.tableDate.push(addressItem)
                        }
                    })
                }
            })
        },

        getSeePhoto(item) {
            // this.photoPath = url.url + '/file/object?path=' + item.path;
            // this.seePhotoDialog = true;
            console.log(item.memo.split(".")[1])
            if (item.memo.split(".")[1] === "pdf") {
                console.log(item.type)
                window.open(url.url + '/file/object/preview?path=' + item.path,"_blank")
            }else {
                this.photoPath = url.url + '/file/object?path=' + item.path;
                this.seePhotoDialog = true;
            }
        }
    },
    created() {
        this.getDate();
    }
}
</script>

<style scoped lang="less">
.pa-2 {
    text-align: left;
}

.file span {
    margin-top: 24px;
    text-align: center;
    border: 1px solid #64B5F6;
    color: #FFFFFF;
    background-color: #64B5F6;
    border-radius: 4px;
    display: inline-block;
    width: 80%;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
}

.policy-resume {
    display: block;
    width: 100%;
    min-height: 80px;
    padding: 8px;
    border: 1px #8a8f97 dashed;
    background-color: #f5f7fa;
    border-radius: 8px;
}

.authentication-information {
    color: #1090e7;
    font-weight: bold;
    position: relative;

    span {
        position: absolute;
        left: 0;
        display: inline-block;
        height: 20px;
        width: 3px;
        background-color: #1090e7;
    }
}

.text-content {
    text-align: left;
    margin: 0 8px;
    color: #000000;
    font-size: 16px;

    span {
        color: #8a8f97;
    }

    .content {
        margin-left: 8px;
        color: #2a3746;
    }
}

.button-width {
    width: 96px;
}

</style>